@import 'bootstrap/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

.swal2-container {
  z-index: 100000000000!important;
}

// colors
$dark-color: #000033;
$grey-color: #444444;

.text-danger {
  font-weight: 500!important;
}

* {
  outline: none;
}

@media (min-width: 1400px) {
  .container-sm {
    max-width: 945px!important;
  }
}

.swal2-icon {
  margin-top: 30px;
}
.swal2-container.swal2-center>.swal2-popup {
  padding-bottom: 40px;
}
.swal2-close {
  outline: none;
  box-shadow: none!important;
}

.btn {
  border-radius: 2px;
  border: 0;
  outline: 0px;
  padding: 0.25rem 2rem;
  height: 2.8rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 36px;
  cursor: pointer;
  background-color: #ed6337;
  color: white;
  box-shadow: none !important;
  &:hover {
    background-color: #ff784d;
    color: white;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  // padding-top: 135px;
}

/**
====== HEADER
 */
.header {
  position: sticky;
  z-index: 9999;
  width: 100%;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header--brand {
  display: block;
  width: fit-content;
  margin: auto;
  @media screen and (max-width: 600px) {
    margin-right: -22px;
  }
}

.header-top {
  border-bottom: 2px solid #ececec;
  padding: 15px 0;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.header--logo {
  margin: 0 auto;
  display: block;
  @media screen and (min-width: 840px) {
    max-width: 200px;
  }
}

.main-search {
  .show-search {
    outline: none;
    font-size: 0;
    width: 30px;
    height: 30px;
    border: none;
    background: #efefef url('../images/search-icon.jpg') center no-repeat;
    background-size: 90%;
    border-radius: 5px;
  }

  form {
    .search-bar {
      border-radius: 20px;
      border: 1px solid #c9c9c9;
      box-shadow: inset 0 0 4px #c9c9c9;
      width: 270px;
      line-height: 36px;
      padding-left: 15px;
      font-weight: 500;
      background: #efefef url('../images/search-icon.jpg') right 15px center
        no-repeat;
      background-size: 22px;
      padding-right: 48px; 
    }
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 4;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-bar {
        width: 100%;
      }
      button {
        background: none;
        border: 0;
        font-size: 24px;
        font-weight: bold;
        outline: none;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

}

//.menu-btn {
//  background: #ffffff url("../images/menu-icon.png") top center no-repeat;
//  padding-top: 20px;
//  border: none;
//  background-size: 30px;
//  font-weight: 500;
//  margin-right: 15px;
//}
.menu-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    margin-right: 5px;
  }

  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }

    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }

    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    margin-top: -10px;
    font-weight: 500;
  }

  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
}

.hamburger-menu {
  position: relative;
}

.hamburger-menu-c {
  // display: none;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 10px 10px 0;
  min-width: 220px;
  border: 1px solid #eeeeee;

  ul {
    list-style: none;
    padding: 10px;
    margin-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid rgb(225, 225, 228);
    }
  }

  a, button {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
  }

  i {
    margin-right: 10px;
    font-size: 23px;
  }

  li {
    margin-bottom: 10px;

    &:hover {
      color: #fe6b00;
      // svg path {
      //   fill: #fe6b00;
      // }
    }
  }
}

.right-menu {
  .right-menu--item {
    margin-right: 20px;
    text-decoration: none;

    @media screen and (max-width: 1150px) {
      display: none;
    }

    img {
      max-height: 23px;
      margin-bottom: 3px;
    }

    i {
      display: block;
      text-align: center;
      font-size: 30px;
      line-height: 26px;
      color: #464650;
    }

    span {
      color: $dark-color;
      font-weight: 600;
      font-size: 10px;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .cart-box {
    position: relative;
    display: flex;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: 767px) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
    .basket-btn {
      color: #fd6a00;
      border: 1px solid #fea666;
      background: white;
      background-size: 18px;
      padding: 6px 9px;
      align-self: center;
      font-weight: 600;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      text-decoration: none;
      position: relative;
      z-index: 3;
      transition: all 0.2s ease-in-out;
      font-family: 'Montserrat', sans-serif;
      margin-right: 6px;
      display: flex;
      align-items: center;
      text-decoration: none;

      i {
        font-weight: 100;
        font-size: 21px;
        line-height: 0;
        margin-right: 5px;
      }

      .quantity {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background: #fd6a00;
        color: white;
        border-radius: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 21px;
      }

      @media screen and (max-width: 768px) {
        font-size: 0;
        padding: 15px 0;
        border: none;
        margin: 0;
        padding-right: 7px;
        span {
          right: 0!important;
        }
        i {
          font-size: 23px;
        }
      }
    }
    .cart-info {
      position: absolute;
      min-width: 470px;
      background: white;
      box-shadow: 0 0 5px rgba(34, 34, 43, 0.15);
      right: -8px;
      top: -10px;
      border-radius: 4px;
      padding: 0 22px;
      transition: all 0.2s ease-in-out;
      visibility: hidden;
      opacity: 0;
      z-index: 2;

      &.true {
        visibility: visible;
        opacity: 1;
      }

      .vide {
        padding-top: 72px;
        padding-bottom: 72px;
        & > div:first-of-type {
          font-size: 1.625rem;
          font-weight: 500;
        }
        & > div:last-of-type {
          margin-top: 8px;
          color: rgb(70, 70, 80);
          font-weight: 500;
        }
      }

      .plein {
        padding-top: 25px;
        padding-bottom: 20px;
        .title {
          font-size: 19px;
          margin-bottom: 25px;
        }
        .items {
          max-height: 400px;
          overflow: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
              )
              0 100%;
          background-repeat: no-repeat;
          background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
          background-attachment: local, local, scroll, scroll;

          &::-webkit-scrollbar {
            display: none;
          }
          .item {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            &:last-child {
              margin-bottom: 0;
            }
            .item-image-block {
              position: relative;
              .q {
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
                background: #fd6a00;
                color: white;
                text-align: center;
                line-height: 21px;
                border-radius: 100%;
                font-size: 12px;
                font-weight: 500;
                top: 0;
                left: 0;
              }
              .item-image {
                width: 85px;
                height: 50px;
                border-radius: 4px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
            .item-info {
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              .item-name {
                font-size: 17px;
                font-weight: 500;
                line-height: 19px;
              }
              .item-sub {
                font-size: 15px;
                color: #a2a2a2;
              }
              .item-price {
                font-size: 18px;
                font-weight: 600;
                margin-top: 4px;
              }
            }
            .item-delete {
              padding: 0;
              background: transparent;
              border: 0;
              font-size: 20px;
              color: #adadad;
              outline: none;
              cursor: pointer;
              &:hover {
                color: #c2c2c2;
              }
            }
          }
        }
        .sous-total {
          text-align: right;
          font-size: 19px;
          font-weight: 500;
          margin-top: 10px;
        }
        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .button {
            color: white;
            width: 100%;
            border: 0;
            border-radius: 3px;
            padding: 9px;
            font-size: 14px;
            font-weight: 500;
            outline: none;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            background-color: #3abc00;
          }
        }
      }
    }
  }
}

.main-menu {
  margin: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 15px;
  min-height: 54px;

  ul {
    padding: 0;
    margin: 0;
    line-height: 35px;
    @media screen and (max-width: 1032px) {
      list-style-type: none;
      white-space: nowrap;
      overflow-x: auto;
    }
  }

  .main-menu--item {
    display: inline-block;
    font-weight: 600;
    font-size: 15px;

    a {
      color: inherit;
      text-decoration: none;
      display: block;
      transition: color 0.1s ease-in-out;
      color: $grey-color;

      &.active {
        color: #fe6b00;
        // border-bottom: 2px solid #fe6b00;
      }

      &:hover {
        color: #fe6b00;
        // border-bottom: 2px solid #fe6b00;
      }
    }

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .highlighted {
    position: sticky;
    right: 0;
    z-index: 0;
    visibility: hidden;
    @media screen and (max-width: 768px) {
      & span {
        display: none;
      }
    }
    &.true {
      visibility: visible;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background-color: white;
      box-shadow: -19px 0 10px rgba(255, 255, 255, 0.95);
      z-index: -1;
      border-radius: 8px;
    }
    a {
      background: #f5a748;
      color: #fff;
      border-radius: 5px;
      padding: 7px 15px;
      text-shadow: 1px 1px #545454;
      line-height: 24px;

      &:hover {
        background: #e31818;
        color: #fff;
        border-bottom: none;
      }
    }
  }
}

/**
========= Main Banner
 */

.main-banner {
  padding-top: 60px;
  // background: url('../images/banner/bottom-line.png') no-repeat bottom,
  //   url('../images/banner/ramadan-bg.jpg') no-repeat bottom;
  // background: url('../images/banner/bottom-line.png') no-repeat bottom,
  //   url('../images/banner/black-friday-banner.png') no-repeat bottom;
  // background-size: contain, cover;
  position: relative;
  margin-bottom: 60px;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   height: 80%;
  //   width: 90px;
  //   background: url('../images/banner/right-ilust.png') no-repeat right;
  //   background-size: contain;
  // }

  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   width: 131px;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   height: 80%;
  //   background: url('../images/banner/left-ilust.png') no-repeat left;
  //   background-size: contain;
  // }

  .container {
    @media screen and (min-width: 1200px) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.main-banner-slider {
  z-index: 1;
  padding-bottom: 60px;

  .slider-img {
    width: 100%;
  }

  .slide-item {
    padding: 10px;
    &>div {
      position: relative;
      overflow: hidden;
    }
    &:hover {
      .buy {
        transform: translate(-50%, 0);
      }
    }
    .buy {
      position: absolute;
      bottom: 25px;
      left: 50%;
      width: 80%;
      background-color: #ff6633;
      color: #ffffff;
      padding: 10px 20px;
      text-decoration: none;
      text-align: center;
      border-radius: 3px;
      transform: translate(-50%, calc(100% + 25px));
      font-size: 20px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
      font-weight: bold;
      @media screen and (max-width: 1440px) {
        font-size: 17px;
      }
      &:hover {
        opacity: 0.95;
      }
    }
  }

  .slick-dots {
    bottom: 25px;

    li button:before {
      font-size: 12px;
      color: #7d7d7d;
      opacity: 1;
    }

    li.slick-active button:before {
      color: #ff6633;
      opacity: 1;
    }
  }
}

/**
========== Section header
 */
.section-header {
  text-align: center;
  padding-bottom: 25px;

  img {
    display: block;
    width: 50px;
    margin: 0 auto 10px;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 2px;
  }

  h4 {
    font-weight: 300;
    font-size: 15px;
  }
}

/**
======= G Card
 */

.g-card {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }

  .g-card-status {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    font-size: 14px;
    padding: 2px 22px;
    font-weight: 500;
    transform: rotate(-45deg) translate(-28px, -8px);
    width: 100px;
    text-align: center;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    cursor: default;
  }

  .g-date {
    color: #ff6633;
    margin-bottom: 15px;
  }

  .g-time-down {
    display: flex;
    margin-top: 10px;
    i {
      font-size: 15px;
      color: #ff6633;
      margin-right: 5px;
    }
    & > div {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
      span {
        font-size: 13px;
        font-weight: normal;
        opacity: 0.8;
      }
    }
  }

  .g-price-container {
    min-height: 75px;
    align-items: flex-end;
  }

  .g-price {
    label {
      color: #666666;
      margin-bottom: 0;
    }

    .event-item-old-price {
      font-size: 14px;
      color: #57a8dc;
      text-decoration: line-through
    }

    p {
      font-weight: 700;
      color: #ff6633;
      font-size: 27px;
      line-height: 25px;

      span {
        font-size: 17px;
        margin-left: 2px;
      }
    }
  }

  .img-container {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .g-category {
    background: #071a35;
    border-radius: 5px;
    font-weight: 500;
    color: #ffffff;
    font-size: 15px;
    padding: 5px 30px;
    margin-bottom: 15px;
  }

  .g-title {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 4px;

    min-height: 44px;
    max-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: 0;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .g-address {
    color: #666666;
    font-size: 12px;
    max-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    margin-top: 8px;

    &:before {
      content: '';
      width: 20px;
      display: inline-block;
      height: 20px;
      background: url('../images/icon-pin.png') no-repeat left center;
      background-size: contain;
      margin-bottom: -3px;
    }
  }

  .g-card-desc {
    border-width: 0 1px 1px 1px;
    border-color: #d9d9d9;
    border-style: solid;
    border-radius: 0 0 15px 15px;
    padding: 15px;
    background-color: #fff;
  }

  .g-btn-orange {
    background-color: #ff6633;
    border-radius: 7px;
    color: #ffffff;
    align-self: flex-end;
    line-height: 30px;
    padding: 0 20px;
    margin-bottom: 5px;
    text-decoration: none;
  }
  .g-btn-grey {
    background-color: #8f8f8f;
    border-radius: 7px;
    color: #ffffff;
    align-self: flex-end;
    line-height: 30px;
    padding: 0 20px;
    margin-bottom: 5px;
    text-decoration: none;
    @media screen and (max-width: 1400px) {
      padding: 0 6px;
    }
  }
}

/**
========= Store product HP
 */
.store-pr {
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: rgba(34, 34, 43, 0.08) 0px -1px 4px -2px,
      rgba(34, 34, 43, 0.1) 0px 14px 16px -6px,
      rgba(34, 34, 43, 0.1) 0px 4px 22px 0px;
    .details {
      transform: translateY(-49px);
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }

  .details {
    position: absolute;
    transition: all 0.3s ease 0s;
    bottom: -100%;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: black;
    padding: 6px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.true {
      transform: translateY(0);
      opacity: 1;
      bottom: 0;
      .top a.sub {
        max-height: none;
        overflow: visible;
        max-width: none;
      }
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        font-size: 13px;
        color: inherit;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        display: block;
        &.sub {
          opacity: 0.6;
          max-height: 19px;
          overflow: hidden;
          max-width: 90%;
        }
        &:hover {
          color: #fe6b00;
        }
      }
      .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 30px;
        &.true {
          transform: rotate(45deg);
        }
      }
    }
    .desc {
      font-size: 12px;
      margin-top: 3px;
      display: block;
      color: inherit;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      max-height: 91px;
      &:hover {
        color: #fe6b00;
      }
    }
    .btn {
      transition: all 0.3s ease 0s;
      text-decoration: none;
      color: inherit;
      border: 0;
      background: transparent;
      padding: 0;
      width: 34px;
      height: 34px;
      cursor: pointer;
      outline: none;
      box-shadow: none;
      text-align: right;
      font-size: 21px;
      &:hover {
        color: #fe6b00;
      }
    }
  }
}

/**
========= Categories
 */
.categories {
  .categorie {
    padding: 50px 0 65px;
    .section-header {
      padding-top: 0;
    }
  }
}

/**
========= Product
 */
.product-article {
  border: 1px solid rgb(225, 225, 228);
  background-color: white;
  transition: box-shadow 0.3s ease-in-out 0s;
  padding: 0.5rem;

  img {
    width: 100%;
  }

  &.small {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    header {
      .new-price {
        font-size: 1rem;
        line-height: 14px;
        margin-bottom: 0;
      }
      .old-price {
        font-size: 0.8rem;
        line-height: 19px;
      }
    }
  }

  &:hover {
    box-shadow: rgba(34, 34, 43, 0.16) 0px -1px 2px -2px,
      rgba(34, 34, 43, 0.08) 0px 10px 14px -6px,
      rgba(34, 34, 43, 0.1) 0px 4px 18px -2px;
  }

  a {
    text-decoration: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    &>div {
      width: 65%;
    }
    a {
      color: rgb(70, 70, 80);
      font-weight: 500;
      font-size: 1rem;
      line-height: 23px;
      text-decoration: none;
    }
    .new-price,
    .old-price {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      span {
        margin-right: 5px;
      }
    }
    .new-price {
      margin: 0px 0px 0.25rem;
      color: #fd6a00;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 22px;
      span {
        font-size: 0.875rem;
        line-height: 16px;
      }
    }
    .old-price {
      color: #464650;
      font-weight: 400;
      font-size: 1rem;
      line-height: 23px;
      text-decoration: line-through;
      span {
        font-size: 0.625rem;
        line-height: 18px;
      }
    }
    .per-price {
      padding: 2px 0.25rem;
      border-radius: 2px;
      font-weight: 600;
      font-size: 0.75rem;
      color: rgb(255, 255, 255);
      background-color: rgb(63, 174, 62);
    }
  }
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    button {
      border-radius: 2px;
      border: 0;
      outline: 0px;
      padding: 0.25rem 2rem;
      height: 2rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      background-color: #fd6a00;
      color: rgb(255, 255, 255);
    }
    a {
      color: rgb(70, 70, 80);
      font-weight: 500;
      font-size: 0.875rem;
      text-decoration: none;
      &:hover {
        color: #fd6a00;
      }
    }
  }
}

.country-dropdown {
  label {
    text-transform: uppercase;
    font-weight: 500;
  }
  button {
    cursor: pointer;
    border: 0px;
    background-color: #8c8c8c;
    color: #ffffff;
    padding: 0px 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    height: 45px;
    outline: 0 !important;
  }
  img {
    width: 30px;
    height: 20px;
  }
  .country-grid {
    margin-top: -5px;
    bottom: 22px;
    z-index: 2;
    // width: 100%;
    min-height: 45px;
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #8c8c8c;
    button {
      width: auto;
      min-width: auto;
      padding: 0px 0px;
    }
  }
}

/**
========= Catalog
 */
.catalog {
  .accordion {
    padding: 15px 0;
    border-bottom: 1px solid;
    border-color: rgb(225, 225, 228);
    cursor: pointer;
    &:hover {
      color: #fd6a00;
    }
    .title {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 21px;
        opacity: 0.6;
      }
      &:hover {
        color: #fd6a00;
        cursor: pointer;
        font-size: 16px;
      }
    }
    .list {
      list-style-type: none;
      padding: 0;
      margin-top: 14px;
      margin-bottom: 0;
      li {
        margin-bottom: 0.4rem;
        a {
          font-weight: 400;
          font-size: 15px;
          color: #888889;
          text-decoration: none;
          &:hover {
            color: #fd6a00;
          }
        }
      }
    }
  }
  .p-info {
    margin-top: 1rem;
    color: #74747b;
    font-weight: 500;
    font-size: 1rem;
    line-height: 23px;
  }
  header {
    &>img {
      width: 100%;
    }
    .brand {
      width: 200px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      text-decoration: none;
      overflow: hidden;
      display: block;
      box-shadow: 0 7px 10px #e7e7e7;
      margin: 0 12px;
      margin-top: -36px;
      img {
        max-width: 100%;
      }
    }
  }
}

/**
========= Single Product
 */
.single-product {
  padding: 60px 0;
  .title {
    margin-bottom: 1.5rem;
    color: rgb(34, 34, 43);
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 26px;
  }
  .price-block {
    display: flex;
    align-items: flex-start;
    .new-price,
    .old-price {
      display: flex;
      align-items: flex-start;
      span {
        margin-right: 8px;
      }
    }
    .new-price {
      margin: 0px 0px 0.25rem;
      color: #fd6a00;
      font-size: 2rem;
      font-weight: 700;
      line-height: 30px;
      span {
        font-size: 1.125rem;
        line-height: 16px;
        font-weight: 500;
      }
    }
    .old-price {
      color: #464650;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 23px;
      text-decoration: line-through;
      span {
        font-size: 0.625rem;
        line-height: 18px;
      }
    }
    .per-price {
      padding: 2px 0.25rem;
      border-radius: 2px;
      font-weight: 600;
      font-size: 0.75rem;
      color: white;
      background-color: #3fae3e;
      margin-left: 16px;
      margin-top: 6px;
    }
  }
  p {
    color: rgb(70, 70, 80);
    font-weight: 400;
    font-size: 1rem;
    line-height: 23px;
  }
}

.description {
  margin: 35px 0;
  h3 {
    color: #22222b;
    font-weight: 600;
    font-size: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    span {
      min-width: fit-content;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e6e6e6;
      margin-left: 12px;
    }
  }
  h5 {
    margin-top: 45px;
    opacity: 0.9;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
  }
  h6 {
    font-weight: 500;
    opacity: 0.8;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  p {
    font-size: 16px;
    opacity: 1;
    text-align: justify;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      font-size: 15px;
      margin-bottom: 2px;
      opacity: 0.8;
      &:before {
        content: '- ';
      }
    }
  }
}

/**
========== info HP
 */
.g-info {
  text-align: center;

  img {
    display: block;
    margin: 0 auto 15px;
    height: 60px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;

    strong {
      font-weight: 500;
    }
  }

  p {
    color: #7e7f81;
    line-height: 16px;
    font-size: 13px;
  }
}

.features {
  padding: 55px 0 45px;
}

/**
========= SelectBox
 */
.select-box--box {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  min-width: 85px;
}

.select-box--container {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.select-box--selected-item {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 8px 15px;
  vertical-align: middle;
}

.offer-old-price {
  margin-right: 12px;
  font-size: 14px;
  color: #57a8dc;
  text-decoration: line-through
}

.select-box--items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #cdcdcd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  z-index: 3;
  max-height: 370px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all .2s ease-in-out;
  &.shown {
    visibility: visible;
    opacity: 1;
  }
  div {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 8px;
    padding-left: 15px;
    font-size: 14px;
    transition: all .2s ease-in-out;
    &:hover {
      background-color: #fafafa;
    }
    &.disabled {
      cursor: default;
      &:hover {
        background-color: transparent!important;
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.select-box--arrow {
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  span {
    font-size: 24px;
    color: #cdcdcd;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

/**
========= Footer
 */
.main-footer {
  background-color: #f3f3f3;
}

.top-footer {
  padding: 50px 0;
}

.footer-title {
  text-transform: uppercase;
  color: #161616;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
}

.footer-left {
  p {
    color: #111111;
    margin-bottom: 35px;
    font-weight: 500;
  }
}

.footer-logo {
  margin-bottom: 30px;

  img {
    width: 170px;
  }
}

.footer-menu-bloc {
  margin-bottom: 15px;
}

.footer-menu {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: $grey-color;
  }
}

.btn-app {
  display: block;
  font-weight: 500;
  color: #ffffff !important;
  text-decoration: none !important;
  border-radius: 6px;
  padding: 12px 10px 12px 44px;
  margin-bottom: 15px;

  &.orange {
    background: #ff6633 url('../images/download-icon-1.png') left 9px center
      no-repeat;
    background-size: 26px;
  }

  &.purple {
    background: #cc3366 url('../images/download-icon-2.png') left 9px center
      no-repeat;
    background-size: 28px;
  }
}

.newsletter {
  border: 1px solid #aaaaaa;
  padding: 10px;
  border-radius: 15px;

  .text-danger {
    position: absolute;
    bottom: -42px;
  }

  p {
    font-weight: 300;
  }

  h3 {
    font-weight: 300;
  }

  .email {
    flex: 4;
    input[type='email'] {
      border: none;
      line-height: 40px;
      padding: 0 10px;
      font-weight: 500;
      width: 100%;
    }
  }

  input[type='submit'] {
    flex: 0 1;
    line-height: 40px;
    background-color: #ed6337;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.bottom-footer {
  background: #ffffff url('../images/banner/bottom-line.png') bottom no-repeat;
  background-size: 100% 5px;
  padding: 15px 0;

  p {
    margin-bottom: 0;
    color: #0a3454;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column-reverse;

      & > * {
        margin-bottom: 15px;
      }
    }
  }
}

.sm-links {
  &.light a {
    color: white!important;
  }
  a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 100%;
    text-align: center;
    line-height: 29px;
    margin-left: 6px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &.facebook {
      color: #3b5999;
      &:hover {
        background-color: #3b5999;
        border-color: #3b5999;
      }
    }
    &.google {
      color: #de4f3e;
      &:hover {
        background-color: #de4f3e;
        border-color: #de4f3e;
      }
    }
    &.instagram {
      color: #add7a5;
      &:hover {
        background-color: #add7a5;
        border-color: #add7a5;
      }
    }
    &.pinterest {
      color: #c8232c;
      &:hover {
        background-color: #c8232c;
        border-color: #c8232c;
      }
    }
    &.linkedin {
      color: #0976b4;
      &:hover {
        background-color: #0976b4;
        border-color: #0976b4;
      }
    }
    &.twitter {
      color: #02aded;
      &:hover {
        background-color: #02aded;
        border-color: #02aded;
      }
    }
    &:hover {
      color: #ffffff;
    }
  }
  img {
    width: 30px;
    margin-left: 5px;
  }
}

.payment-methods img {
  width: 250px;
  max-width: 100%;
}

/**
=========== Simple slider
 */

.simple-slider {
  padding-bottom: 60px;

  .slide-item {
    padding: 10px;
    img {
      width: 100%;
      border-radius: 8px;

      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .slick-dots {
    bottom: 20px;

    li {
      width: 45px;
      height: 6px;
      margin: 0 3px;
    }

    li button:before {
      font-size: 0;
      background-color: #e8e8e8;
      opacity: 1;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }

    li.slick-active button:before {
      background-color: #ff6633;
      opacity: 1;
    }
  }
}

/**
========== Coming soon
 */

.coming-soon {
  background-color: #200d5d;
  padding: 50px 0 70px;

  .section-header {
    color: #ffffff;
  }

  .store-pr {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    a {
      pointer-events: none;
    }
    .details {
      background-color: rgb(18, 18, 23);
      color: white;
    }
    .bottom {
      a {
        display: none;
      }
    }
  }
}

.sc-slider {
  padding-bottom: 80px;

  .slick-active .slide-item {
    opacity: 1;
  }

  .slide-item {
    padding: 10px;
    opacity: 0.5;
    transition: 0.2s ease;

    .img-container img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .slick-dots {
    bottom: 25px;

    li {
      width: 45px;
      height: 6px;
      margin: 0 3px;
    }

    li button:before {
      font-size: 0;
      background-color: #928f9a;
      opacity: 1;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }

    li.slick-active button:before {
      background-color: #ff6633;
      opacity: 1;
    }
  }
}

.mv-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  .mv-card-desc {
    padding: 10px;
  }

  .mv-title {
    font-size: 18px;
  }

  .mv-duration {
    display: block;
    color: #ff6633;

    &:before {
      content: '';
      background: url('../images/time-icon.png') no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: sub;
    }
  }

  .mv-tags {
    color: #666666;
    font-weight: 500;
  }

  .mv-rating {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #e8e8e8;
    color: #5c5c5c;
    font-weight: 700;
    display: block;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
  }
}

/**
========= Modal
 */
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: all 0.2s ease-in-out;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  &.true {
    visibility: visible;
    opacity: 1;
  }
  .back {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  section {
    background-color: white;
    padding: 25px 25px;
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;
  }
  .my-container {
    width: 1024px;
    position: relative;
    &.small-container {
      width: 765px;
    }
    @media screen and (max-width: 970px) {
      width: 100%;
      padding: 0 15px;
    }
  }
}

/**
========= Address Modal
 */
.address-modal {
  input, select {
    width: 100%;
    border: 1px solid #e6e6e6;
    background: white;
    padding: 8px 15px;
    border-radius: 4px;
    height: 39px;
  }
  .bnt-submit {
    width: auto!important;
    margin: auto;
    padding: 12px;
    padding-left: 50px!important;
    padding-right: 50px!important;
    border: 0;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.blue {
      background-color: #57a8dc;
    }
    &.green {
      background-color: #3abc00;
    }
  }
  .autocomplete {
    input {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 240px;
      height: 40px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 50%;
      margin-left: -120px;
      margin-top: 10px;
    }  
  }
}

.pac-container {
  z-index: 9999!important;
}

.address-box {
  button {
    width: auto!important;
    padding: 10px 18px!important;
    margin: 20px auto;
    margin-bottom: 0;
    border: 0;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #57a8dc;
  }
  .table {
    button {
      margin: auto 5px!important;
      padding: 0!important;
      background: transparent;
      color: #c7c7c7;
      font-size: 18px;
      &:hover {
        color: black;
      }
    }
    th, td {
      padding: 8px;
      vertical-align: middle;
      border-top: 0;
      border-bottom: 1px solid #dee2e6;
    }
    th {
      font-size: 15px;
    }
    td {
      font-size: 13px;
    }
  }
}

/**
========= Buy now modal
 */
.buy-now-modal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  &.true {
    visibility: visible;
    opacity: 1;
    .box {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
  .box {
    background-color: white;
    // min-width: 920px;
    width: 880px;
    padding: 45px 40px;
    position: relative;
    align-items: center;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.3s;
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;
    height: auto;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 985px) {
      min-width: 100%;
      & img {
        margin-bottom: 15px;
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      outline: none;
      padding: 10px;
    }
    h3 {
      font-size: 26px;
      margin-bottom: 6px;
      color: $dark-color;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #071a35;
      margin: 0;
      line-height: 22px;
    }
    table {
      width: 100%;
      margin: 24px 0;
      th {
        font-weight: 500;
        color: #5e5e5e;
        background-color: #f6f6f6;
        padding: 5px 0;
      }
      td {
        font-weight: bold;
        padding: 10px 0;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      button, a {
        color: white;
        width: 50%;
        text-transform: uppercase;
        border: 0;
        border-radius: 3px;
        padding: 9px 5px;
        font-size: 13px;
        font-weight: 400;
        outline: none;
        cursor: pointer;
        display: block;
        text-decoration: none;
        &:first-child {
          margin-right: 10px;
          background-color: #57a8dc;
        }
        &:last-child {
          margin-left: 10px;
          background-color: #3abc00;
        }
      }
    }
    .actions.plan {
      margin-top: 24px;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
  &.plan {
    .box {
      height: 90% !important;
      overflow: hidden;
    }
  }
  .plan-container {
    height: 94%;
    overflow: auto;
  }
}

/**
========= Account modal
 */
 .account-modal {
  @media screen and (max-width: 767px) {
    display: block;
    padding-top: 20px;
    .or {
      display: none!important;
    }
  }
  .loading-popup {
    background-color: transparent;
    position: fixed;
    .lds-spinner {
      transform: none;
      div:after {
        background: #ffffff;
      }
    }
  }
  .modal-container {
    position: relative;
    .close-btn {
      position: absolute;
      top: 5px;
      right: 20px;
      z-index: 9;
      cursor: pointer;
      background: none;
      border: 0;
      font-size: 28px;
      opacity: 0.5;
      outline: none;
      &:hover {
        opacity: 1;
      }
    }
    .or {
      position: absolute;
      left: 50%;
      top: 0;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      width: 82px;
      height: 82px;
      border-radius: 100%;
      text-align: center;
      line-height: 2.6;
      margin-top: -40px;
      transform: translateX(-50%);
      z-index: 4;
      background-color: #fd6a00;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.21);
    }
  }
}


/**
========== Passed events
 */

.passed-events {
  background-color: #071d39;
  padding: 50px 0;

  .section-header {
    color: #ffffff;
  }

  .g-card {
    position: relative;
    opacity: 0.5;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.g-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 40px;
  padding: 0;

  .previous a,
  .next a {
    font-size: 17px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    margin-top: 2px;
    cursor: pointer;
  }
  .previous a {
    background: #fff url('../images/left-arrow.png') left 9px center no-repeat;
    background-size: 10px;
    margin-right: 14px;
  }
  .next a {
    background: #fff url('../images/right-arrow.png') left 11px center no-repeat;
    background-size: 10px;
    margin-left: 14px;
  }
  .pagination-item {
    border: none;
    background: #fff;
    border-radius: 50%;
    font-weight: 500;
    color: #ff5722;
    font-size: 14px;
    width: 28px;
    height: 28px;
    line-height: 29px;
    margin: 2px 3px;
    display: block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;

    &.active {
      background: #ff5722;
      color: #fff;
    }
  }
  .break-me {
    a {
      color: white;
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
    }
  }
}

/**
========= Form
 */
.contact-from-header {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  h3 {
    color: #000033;
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  p {
    font-weight: 300;
    color: #3c3c3c;
    font-size: 15px;
    line-height: 18px;
  }
}

.form-container {
  max-width: 800px;
  margin: 0 auto 50px;

  &.small-container {
    max-width: 350px;
  }
}

.g-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 6px;
}

.g-input {
  margin-bottom: 13px;

  label {
    color: #000033;
    display: block;
    margin-bottom: 3px;

    span {
      color: #be3d33;
    }
  }

  .forgot {
    color: #3c3c3c;
    text-decoration: none;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
    &:hover {
      color: #fd6a00
    }
  }

  input,
  textarea {
    padding: 3px 15px;
    width: 100%;
    border: 2px solid #57a8dc;
    border-radius: 5px;
    line-height: 35px;

    &::placeholder {
      color: #9f9f9f;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9f9f9f;
    }

    &::-ms-input-placeholder {
      color: #9f9f9f;
    }
  }
}

.g-submit {
  color: #ffffff;
  background-color: #09b500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  min-width: 150px;
  cursor: pointer;
  display: block;
  text-align: center;

  &.blue {
    background-color: #51a9dc;
  }
}

.g-social-media {
  list-style-type: none;
  padding: 0;
  display: flex;
  li {
    width: 33.333%;
    padding: 0 4px;
    button {
      width: 100%;
      display: block;
      text-align: center;
      background: black;
      color: white;
      height: 36px;
      line-height: 34px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      outline: none;
      border: 0;
      cursor: pointer;
      &.facebook {
        background-color: #46629c;
      }
      &.twitter {
        background-color: #00aced;
      }
      &.google {
        background-color: #ea634e;
      }
      i {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }
}

/**
======= Event page
 */

.event-title h1 {
  color: #000033;
  font-weight: 600;
  text-align: center;
  margin: 40px auto;
  margin-top: 70px;
}

.evnet-slider-nav {
  .slick-track {
    height: auto !important;
    display: flex;
    &>div {
      max-width: 164px!important;
    }
  }

  .img-container {
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 10px;
      border: 3px solid transparent;
      height: 95px;
    }
  }

  .slick-current {
    img {
      border-color: #fe6b00!important;
    }
  }

  &.product-slider-nav {
    .img-container {
      padding: 5px;
      img {
        border-radius: 0;
        border: 1px solid #ececec;
        height: auto;
      }
    }
  }
}

.evnet-slider-main {
  img {
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 991px) {
      height: auto!important;
    }
  }
}

.event-info {
  background-color: #031a37;
  color: #ffffff;
  border-radius: 10px;
  padding: 22px 15px;
  text-align: center;
  min-height: calc(100% - 10px);

  .event-logo {
    height: 80px;
    margin: auto;
    display: block;
    cursor: pointer;
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 40px;
    }
  }

  .event-price {
    margin: 15px 0;

    label {
      font-weight: 300;
    }

    h3 {
      font-size: 2.5rem;

      span {
        font-size: 1.7rem;
      }
    }
  }

  .event-date { 
    margin: 15px 0;

    label {
      font-weight: 300;
    }

    h5 {
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .select-box--container {
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    background-color: #2c4a6e;
    font-size: 15px;
    color: #ffffff;
    width: 100%;
  }
  .select-box--selected-item {
    height: 50px;
    padding: 0 15px;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 38px;
    span {
      font-weight: bold;
      min-width: 26%;
      text-align: right;
    }
  }
  .select-box--items {
    background: #2c4a6e;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(3, 26, 55, 0.45);
    div {
      border-color: #264264;
      font-size: 15px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: bold;
        min-width: 26%;
        text-align: right;
      }
      &:hover {
        background-color: #264264;
      }
    }
  }

  .i-button {
    background-color: #3eb100;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
    border: none;
    width: 100%;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    line-height: 20px;
    &:disabled {
      background-color: rgb(149, 149, 149);
      cursor: default;
    }
  }
}

.event-countdown {
  display: flex;
  justify-content: center;

  .countdown-item {
    width: 60px;

    span {
      display: block;
      margin: 0 auto;
    }

    span:first-child {
      width: 50px;
      height: 50px;
      font-size: 20px;
      font-weight: 700;
      border-radius: 50%;
      border: 1px solid #fe6b00;
      line-height: 46px;
      margin-bottom: 5px;
      position: relative;
      //&:after{
      //  content: '';
      //  position: absolute;
      //  display: block;
      //  width: 100%;
      //  height: 100%;
      //  border: 3px solid #fe6b00;
      //  border-radius: 50%;
      //  top: 0;
      //  left: 0;
      //}
    }

    span:last-child {
      text-align: center;
      font-size: 13px;
    }
  }
}

/**
======= Cart
 */
.box {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 25px 27px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.06);
  position: relative;
  .heading {
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 15px;
    margin-bottom: 20px;
    color: $dark-color;
    h4 {
      margin: 0;
      font-size: 28px;
      font-weight: 500;
      color: inherit;
    }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      margin-top: 3px;
      color: inherit;
    }
  }

  @media (max-width: 692px) {
    padding: 20px;
  }
}

// radios
.radio-container {
  display: block;
  position: relative;
  padding-left: 29px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 0;

  a {
    color: #57a8dc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #57a8dc;
    border-radius: 3px;
    &:after {
      content: '';
      position: absolute;
      width: 56%;
      height: 56%;  
      background: #57a8dc;
      border-radius: 2px;
      left: 4px;
      top: 4px;
      transform: scale(0);
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
  }
  input:checked ~ .checkmark:after {
    opacity: 1;
    transform: scale(1);
  }
}

.cart {
  padding: 100px 0;
  background-color: #f9f9f9;
  position: relative;

  &>.loading-popup {
    position: fixed;
    height: calc(100% - 147px);
    bottom: 0;
    top: auto;
  }

  @media (max-width: 570px) {
    padding: 50px 0;
  }

  // panier
  .panier {
    .item {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      padding: 25px 0;
      &>div:last-of-type {
        margin-bottom: 0!important;
      }
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
      .item-image {
        border-radius: 12px;
        height: 107px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 570px) {
          max-width: 200px;
        }
      }
      h4 {
        font-size: 23px;
        margin-bottom: 13px;
        padding-right: 50px;
      }
      label {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 500;
        display: block;
      }
      select {
        background-color: #2c4a6e;
        color: white;
        border-radius: 8px;
        border: 0;
        padding: 9px 12px;
        max-width: 75px;
        width: 75px;
        cursor: pointer;
      }
      input[type='text'] {
        border-radius: 5px;
        padding: 7px 16px;
        border: 2px solid #57a8dc;
        font-size: 14px;
        font-weight: 400;
        height: 39px;
        width: 100%;
      }
      .custom-image-upload {
        position: relative;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 7px;
        border: 2px dashed #eeeeee;
        cursor: pointer;
        color: black;
        .img {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-size: cover;
          background-position: center;
          margin-right: 15px;
        }
        i {
          font-size: 20px;
          margin-right: 7px;
        }
        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
      textarea {
        border-radius: 5px;
        padding: 7px 16px;
        border: 2px solid #57a8dc;
        font-size: 14px;
        font-weight: 400;
        height: 80px;
        min-height: 80px;
        max-height: 200px;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }
      .i-select {
        max-width: none;
        width: 100%;
        padding: 9px 6px;
      }
      button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        &.close {
          position: absolute;
          z-index: 4;
          top: 0px;
          right: -15px;
          width: auto;
          color: black;
          i {
            font-size: 23px;
            margin: 0;
          }
        }
      }
      .total {
        p {
          margin: 0;
          font-size: 15px;
          &.totalN {
            font-size: 20px;
            font-weight: 700;
          }
        }
        @media (max-width: 570px) {
          display: flex;
          flex-wrap: nowrap;
          text-align: left !important;
          margin-bottom: 16px;
        }
      }
    }
  }

  .mode-livraison {
    select {
      width: 100%;
      color: #616161;
      border: 2px solid;
      padding: 12px 8px;
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 18px;
    }
    label {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #616161;
      display: flex;
      align-items: center;
      cursor: pointer;
      a {
        color: inherit;
        font-size: 20px;
        margin-left: 5px;
        text-decoration: none;
      }
    }
  }
  
  .hint {
    background-color: #f5f5f5;
    padding: 12px 38px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 300;
    line-height: 19px;
    max-width: 72%;
    margin-bottom: 14px;
    margin-top: -7px;
    p {
      margin: 0;
      //display: inline;
      strong {
        font-weight: bold;
      }
    }
    @media (max-width: 570px) {
      max-width: 100%;
    }
  }

  .mode-paiement {
    label {
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }
  .code-promo {
    .col-4 {
      max-width: none!important;
      padding: 0!important;
      margin: 0!important;
    }
    h4 {
      font-size: 21px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      a {
        font-size: 32px;
        color: inherit;
        text-decoration: none;
        margin-left: 10px;
      }
    }
    .g-input {
      margin-bottom: 0;
      width: 100%;
    }
    input {
      border: 2px solid #b8b8b8;
      padding: 8px 16px;
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: inherit;
    }
    button {
      background-color: #b8b8b8;
      border: 2px solid #b8b8b8;
      color: white;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      outline: none;
      padding: 0;
    }
  }

  button, .btn {
    width: 100%;
    border: 0;
    border-radius: 5px;
    color: white;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.green {
      background-color: #3abc00;
    }
    &.blue {
      background-color: #57a8dc;
    }
    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .total-box {
    p {
      color: #373738;
      font-weight: 700;
      margin: 0;
      font-size: 14px;
      border: 0;
    }
    hr {
      border-top: 2px solid #f1f1f1;
    }
  }

  // cart-finalized
  &.cart-finalized {
    h2 {
      font-size: 30px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 88px;
        height: 2px;
        display: block;
        background-color: #fd6a00;
      }
    }
    .text-body {
      span {
        font-size: 20px;
        margin-bottom: 13px;
        display: block;
      }
      p {
        font-size: 15px;
        margin-bottom: 7px;
      }
    }
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1370px;
  }
}

/**
========= Profile page
 */
.profile-page {
  padding: 100px 0;
  background-color: #f9f9f9;
  .menu {
    position: sticky;
    top: 180px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 20px;
        button {
          background-color: transparent;
          border: 0;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          font-size: 15px;
          outline: none;
          i {
            margin-right: 10px;
            font-size: 20px;
          }
          &:hover {
            color: #fd6a00;
          }
        }
      }
    }
  }
  .updateProfileForm {
    margin-top: 20px;
    input {
      border-radius: 5px;
      padding: 7px 16px;
      border: 2px solid #57a8dc;
      font-size: 14px;
      font-weight: 400;
      height: 39px;
      width: 100%;
    }
    button {
      width: auto !important;
      padding: 10px 18px !important;
      margin: 20px auto;
      margin-bottom: 0;
      border: 0;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #57a8dc;
    }
  }
  .user-info {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

table {
  thead tr {
    background: #f6f6f6;
    th {
      border: 0!important;
    }
  }
  td {
    color: #5a5a5a;
  }
}

/**
========= Mes commandes page
 */
.mes-commandes-page {
  padding: 100px 0;
  background-color: #f9f9f9;
  table {
    margin-top: 20px;
    button, a {
      text-decoration: none;
      color: white;
      border: 0;
      border-radius: 3px;
      padding: 4px 10px;
      font-size: 13px;
      font-weight: 500;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      margin-left: 7px;
      display: inline-block;
      &.green {
        background-color: #3abc00;
      }
      &.blue {
        background-color: #57a8dc;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-top: 18px;
    li {
      margin-bottom: 6px;
      font-size: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/**
========= Magazine page
 */
.magazine-page {
  background-color: #f9f9f9;
  .container {
    max-width: 1220px;
  }
  .article-item {
    text-decoration: none;
    color: black;
    margin-bottom: 35px;
    display: block;
    transition: all 350ms ease-in-out;
    position: relative;
    box-shadow: rgba(29, 33, 41, 0.08) 0px 2px 10px 0px;
    @media screen and (max-width: 575px) {
      box-shadow: none!important;
      padding-right: 170px;
      .article-img {
        position: absolute!important;
        top: 0;
        right: 0;
        width: 170px;
        height: 91px;
        overflow: hidden;
        .article-topic {
          font-size: 12px;
        }
      }
      .article-info {
        padding: 0 25px 20px 0!important;
      }
    }
    &:hover {
      box-shadow: rgba(29, 33, 41, 0.15) 0px 2px 40px 0px;
    }
    .article-img {
      position: relative;
      .article-topic {
        position: absolute;
        background-color: #c1463d;
        padding: 3px 8px;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .article-info {
      padding: 20px 25px;
      .article-title {
        font-size: 20px;
        min-height: 50px;
        max-height: 50px;
        overflow: hidden;
        position: relative;
        display: block;
      }
      .article-date {
        opacity: 0.7;
      }
    }
  }
  .featured-article {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-right: 0;
    .article-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 60px;
      opacity: 0.8;
    }
    .article-img {
      position: relative!important;
      width: 100%!important;
      clip-path: polygon(0% 0%, 0% 100%, 95% 100%, 100% 0%);
      @media screen and (max-width: 768px) {
        clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 95%);
      }
    }
    .topic {
      margin-top: 30px;
      @media screen and (max-width: 575px) {
        margin-top: 10px;
      }
      .article-topic {
        padding: 3px 5px;
        top: 10px;
        right: 10px;
        color: black;
        border: 1px solid black;
        font-size: 14px;
      }
    }
    .article-title {
      font-size: 30px;
      line-height: 34px;
      margin-top: 20px;
      font-weight: 700;
    }
    .article-date {
      font-size: 14px;
      opacity: 0.8;
      display: flex;
      align-items: center;
      i {
        font-size: 16px;
        margin-right: 7px;
      }
    }
    .read-more {
      font-size: 20px;
      font-weight: 600;
      color: black;
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-top: 70px;
      @media screen and (max-width: 575px) {
        display: none;
      }
      i {
        font-weight: 500;
        font-size: 28px;
        margin-left: 4px;
      }
    }
  }

  &.article-detail {
    background-color: white;
    .article-img {
      clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 85%);
      img {
        width: 100%;
      }
    }
    .article-info {
      padding-bottom: 100px;
      .container {
        max-width: 800px;
      }
      .breadcrumb {
        background-color: transparent;
        padding: 0;
        .breadcrumb-item {
          font-size: 15px;
          font-weight: 500;
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
      h1 {
        font-size: 48px;
        font-weight: 700;
      }
      span {
        font-size: 14px;
        opacity: 0.8;
        display: flex;
        align-items: center;
        margin-top: 20px;
        i {
          font-size: 16px;
          margin-right: 7px;
        }
      }
      .article-content {
        margin-top: 30px;
        p {
          font-size: 18px;
          line-height: 1.5;
          color: #545454;
          font-weight: 300;
          b, strong {
            font-weight: 600;
          }
          a {
            color: black;
            font-weight: 400;
          }
        }
      }
    }
  }
}

/**
========= Loading page
 */
.loading-page {
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  // z-index: 999999999999;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dddddd;

  padding: 150px 0;
  @keyframes rotateIcon {
    100% {
      transform: rotate(360deg);
    }
  }
  img {
    animation: rotateIcon 2.2s linear infinite;
    width: 85px;
  }
}
/**
========= loading popup
 */
.loading-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.65);
    margin-top: -10px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #57a8dc;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }  
}

/**
========= Download App Menu
 */
 .download-app-menu {
  border-bottom: 1px solid #dddddd;
  padding: 6px 0;
  .right {
    img {
      width: 28px;
      margin-right: 8px;
    }
    .title {
      display: block;
      font-size: 13px;
      font-weight: 600;
    }
    .subtitle {
      font-size: 12px;
      opacity: 0.6;
      display: block;
      margin-top: -1px;
    }
  }
  a {
    background: #57a8dc;
    color: white;
    border: 0;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 14px;
    text-decoration: none;
    display: block;
  }
  .close-btn {
    background: none;
    border: 0;
    padding: 0;
    font-size: 20px;
    margin-left: 9px;
    color: #b6b6b6;
    outline: none;
  }
}

/**
========= Cookie Reminder
 */
.cookie-reminder {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  color: white;
  padding: 15px 25px;
  button {
    background: transparent;
    color: white;
    border: 0;
    font-size: 47px;
    padding: 0;
    line-height: 1;
    outline: none;
    cursor: pointer;
  }
}

/**
========= Not Found Page
 */
.not-found-page {
  .p404 {
    font-size: 150px;
    color: #ed6337;
    line-height: 120px;
    margin-bottom: 22px;
  }
  h1 {
    font-weight: 400;
    color: #373737;
  }
  p {
    font-size: 15px;
    font-weight: 500;
    color: #8f8f8f;
  }
  .btn {
    margin-top: 25px;
    border-radius: 5px;
  }
  padding: 100px 0;
}

/**
======= Search Page
 */
.search-page {
  padding: 90px 0 80px;
  h1 {
    font-weight: 400;
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
    margin-top: 16px;
  }
}

/////////// oauth-loading
.oauth-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999999999999999999999999999999;
}

.forgot-password-modal .loading-popup {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  .lds-spinner {
    div:after {
      background: #57a8dc;
    }
  }
}

.map-location {
  position: absolute;
  top: 10px;
  left: 10px;
  button {
    border-radius: 3px;
    background-color: white;
    color: black;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
    padding-left: 10px!important;
    padding-right: 10px!important;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
  }
}

@media (max-width: 991px) {
  .small-events-slider-hidden-on-mobile {
    display: none;
  }
}

.Toastify__toast-container {
  z-index: 9999999999999999;
}

.provider {
  .section-header {
    margin-top: 30px;
    img {
      display: block;
      width: auto;
      margin: 0 auto 10px;
      max-width: 300px;
    }
  }
}


.header-countdown {
  border-radius: 0;
  gap: 32px;
  padding-top: 12px;
  padding-bottom: 12px; 
  .countdown-item  {
    width: 55px;
    font-weight: bold;
    span:first-child {
      line-height: 38px;
      width: 40px;
      height: 40px;
      font-size: 18px;
      border-color: #ff0000;
      border-width: 1px;
    }
  }
  background-color: #fca732;
  &:hover {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
}


.header-countdown h4 {
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.header-countdown p {
  padding: 8px;
  background-color: #ff0000;
  font-weight: bold;
}@import 'bootstrap/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

.swal2-container {
  z-index: 100000000000!important;
}

// colors
$dark-color: #000033;
$grey-color: #444444;

.text-danger {
  font-weight: 500!important;
}

* {
  outline: none;
}

@media (min-width: 1400px) {
  .container-sm {
    max-width: 945px!important;
  }
}

.swal2-icon {
  margin-top: 30px;
}
.swal2-container.swal2-center>.swal2-popup {
  padding-bottom: 40px;
}
.swal2-close {
  outline: none;
  box-shadow: none!important;
}

.btn {
  border-radius: 2px;
  border: 0;
  outline: 0px;
  padding: 0.25rem 2rem;
  height: 2.8rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 36px;
  cursor: pointer;
  background-color: #ed6337;
  color: white;
  box-shadow: none !important;
  &:hover {
    background-color: #ff784d;
    color: white;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  // padding-top: 135px;
}

/**
====== HEADER
 */
.header {
  position: sticky;
  z-index: 9999;
  width: 100%;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header--brand {
  display: block;
  width: fit-content;
  margin: auto;
  @media screen and (max-width: 600px) {
    margin-right: -22px;
  }
}

.header-top {
  border-bottom: 2px solid #ececec;
  padding: 15px 0;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.header--logo {
  margin: 0 auto;
  display: block;
  @media screen and (min-width: 840px) {
    max-width: 200px;
  }
}

.main-search {
  .show-search {
    outline: none;
    font-size: 0;
    width: 30px;
    height: 30px;
    border: none;
    background: #efefef url('../images/search-icon.jpg') center no-repeat;
    background-size: 90%;
    border-radius: 5px;
  }

  form {
    .search-bar {
      border-radius: 20px;
      border: 1px solid #c9c9c9;
      box-shadow: inset 0 0 4px #c9c9c9;
      width: 270px;
      line-height: 36px;
      padding-left: 15px;
      font-weight: 500;
      background: #efefef url('../images/search-icon.jpg') right 15px center
        no-repeat;
      background-size: 22px;
      padding-right: 48px; 
    }
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 4;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-bar {
        width: 100%;
      }
      button {
        background: none;
        border: 0;
        font-size: 24px;
        font-weight: bold;
        outline: none;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

}

//.menu-btn {
//  background: #ffffff url("../images/menu-icon.png") top center no-repeat;
//  padding-top: 20px;
//  border: none;
//  background-size: 30px;
//  font-weight: 500;
//  margin-right: 15px;
//}
.menu-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    margin-right: 5px;
  }

  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }

    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }

    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    margin-top: -10px;
    font-weight: 500;
  }

  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
}

.hamburger-menu {
  position: relative;
}

.hamburger-menu-c {
  // display: none;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 10px 10px 0;
  min-width: 220px;
  border: 1px solid #eeeeee;

  ul {
    list-style: none;
    padding: 10px;
    margin-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid rgb(225, 225, 228);
    }
  }

  a, button {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
  }

  i {
    margin-right: 10px;
    font-size: 23px;
  }

  li {
    margin-bottom: 10px;

    &:hover {
      color: #fe6b00;
      // svg path {
      //   fill: #fe6b00;
      // }
    }
  }
}

.right-menu {
  .right-menu--item {
    margin-right: 20px;
    text-decoration: none;

    @media screen and (max-width: 1150px) {
      display: none;
    }

    img {
      max-height: 23px;
      margin-bottom: 3px;
    }

    i {
      display: block;
      text-align: center;
      font-size: 30px;
      line-height: 26px;
      color: #464650;
    }

    span {
      color: $dark-color;
      font-weight: 600;
      font-size: 10px;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .cart-box {
    position: relative;
    display: flex;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: 767px) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
    .basket-btn {
      color: #fd6a00;
      border: 1px solid #fea666;
      background: white;
      background-size: 18px;
      padding: 6px 9px;
      align-self: center;
      font-weight: 600;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      text-decoration: none;
      position: relative;
      z-index: 3;
      transition: all 0.2s ease-in-out;
      font-family: 'Montserrat', sans-serif;
      margin-right: 6px;
      display: flex;
      align-items: center;
      text-decoration: none;

      i {
        font-weight: 100;
        font-size: 21px;
        line-height: 0;
        margin-right: 5px;
      }

      .quantity {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background: #fd6a00;
        color: white;
        border-radius: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 21px;
      }

      @media screen and (max-width: 768px) {
        font-size: 0;
        padding: 15px 0;
        border: none;
        margin: 0;
        padding-right: 7px;
        span {
          right: 0!important;
        }
        i {
          font-size: 23px;
        }
      }
    }
    .cart-info {
      position: absolute;
      min-width: 470px;
      background: white;
      box-shadow: 0 0 5px rgba(34, 34, 43, 0.15);
      right: -8px;
      top: -10px;
      border-radius: 4px;
      padding: 0 22px;
      transition: all 0.2s ease-in-out;
      visibility: hidden;
      opacity: 0;
      z-index: 2;

      &.true {
        visibility: visible;
        opacity: 1;
      }

      .vide {
        padding-top: 72px;
        padding-bottom: 72px;
        & > div:first-of-type {
          font-size: 1.625rem;
          font-weight: 500;
        }
        & > div:last-of-type {
          margin-top: 8px;
          color: rgb(70, 70, 80);
          font-weight: 500;
        }
      }

      .plein {
        padding-top: 25px;
        padding-bottom: 20px;
        .title {
          font-size: 19px;
          margin-bottom: 25px;
        }
        .items {
          max-height: 400px;
          overflow: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
              )
              0 100%;
          background-repeat: no-repeat;
          background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
          background-attachment: local, local, scroll, scroll;

          &::-webkit-scrollbar {
            display: none;
          }
          .item {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            &:last-child {
              margin-bottom: 0;
            }
            .item-image-block {
              position: relative;
              .q {
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
                background: #fd6a00;
                color: white;
                text-align: center;
                line-height: 21px;
                border-radius: 100%;
                font-size: 12px;
                font-weight: 500;
                top: 0;
                left: 0;
              }
              .item-image {
                width: 85px;
                height: 50px;
                border-radius: 4px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
            .item-info {
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              .item-name {
                font-size: 17px;
                font-weight: 500;
                line-height: 19px;
              }
              .item-sub {
                font-size: 15px;
                color: #a2a2a2;
              }
              .item-price {
                font-size: 18px;
                font-weight: 600;
                margin-top: 4px;
              }
            }
            .item-delete {
              padding: 0;
              background: transparent;
              border: 0;
              font-size: 20px;
              color: #adadad;
              outline: none;
              cursor: pointer;
              &:hover {
                color: #c2c2c2;
              }
            }
          }
        }
        .sous-total {
          text-align: right;
          font-size: 19px;
          font-weight: 500;
          margin-top: 10px;
        }
        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .button {
            color: white;
            width: 100%;
            border: 0;
            border-radius: 3px;
            padding: 9px;
            font-size: 14px;
            font-weight: 500;
            outline: none;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            background-color: #3abc00;
          }
        }
      }
    }
  }
}

.main-menu {
  margin: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 15px;
  min-height: 54px;

  ul {
    padding: 0;
    margin: 0;
    line-height: 35px;
    @media screen and (max-width: 1032px) {
      list-style-type: none;
      white-space: nowrap;
      overflow-x: auto;
    }
  }

  .main-menu--item {
    display: inline-block;
    font-weight: 600;
    font-size: 15px;

    a {
      color: inherit;
      text-decoration: none;
      display: block;
      transition: color 0.1s ease-in-out;
      color: $grey-color;

      &.active {
        color: #fe6b00;
        // border-bottom: 2px solid #fe6b00;
      }

      &:hover {
        color: #fe6b00;
        // border-bottom: 2px solid #fe6b00;
      }
    }

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .highlighted {
    position: sticky;
    right: 0;
    z-index: 0;
    visibility: hidden;
    @media screen and (max-width: 768px) {
      & span {
        display: none;
      }
    }
    &.true {
      visibility: visible;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background-color: white;
      box-shadow: -19px 0 10px rgba(255, 255, 255, 0.95);
      z-index: -1;
      border-radius: 8px;
    }
    a {
      background: #f5a748;
      color: #fff;
      border-radius: 5px;
      padding: 7px 15px;
      text-shadow: 1px 1px #545454;
      line-height: 24px;

      &:hover {
        background: #e31818;
        color: #fff;
        border-bottom: none;
      }
    }
  }
}

/**
========= Main Banner
 */

.main-banner {
  padding-top: 60px;
  // background: url('../images/banner/bottom-line.png') no-repeat bottom,
  //   url('../images/banner/ramadan-bg.jpg') no-repeat bottom;
  // background: url('../images/banner/bottom-line.png') no-repeat bottom,
  //   url('../images/banner/black-friday-banner.png') no-repeat bottom;
  // background-size: contain, cover;
  position: relative;
  margin-bottom: 60px;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   height: 80%;
  //   width: 90px;
  //   background: url('../images/banner/right-ilust.png') no-repeat right;
  //   background-size: contain;
  // }

  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   width: 131px;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   height: 80%;
  //   background: url('../images/banner/left-ilust.png') no-repeat left;
  //   background-size: contain;
  // }

  .container {
    @media screen and (min-width: 1200px) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.main-banner-slider {
  z-index: 1;
  padding-bottom: 60px;

  .slider-img {
    width: 100%;
  }

  .slide-item {
    padding: 10px;
    &>div {
      position: relative;
      overflow: hidden;
    }
    &:hover {
      .buy {
        transform: translate(-50%, 0);
      }
    }
    .buy {
      position: absolute;
      bottom: 25px;
      left: 50%;
      width: 80%;
      background-color: #ff6633;
      color: #ffffff;
      padding: 10px 20px;
      text-decoration: none;
      text-align: center;
      border-radius: 3px;
      transform: translate(-50%, calc(100% + 25px));
      font-size: 20px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
      font-weight: bold;
      @media screen and (max-width: 1440px) {
        font-size: 17px;
      }
      &:hover {
        opacity: 0.95;
      }
    }
  }

  .slick-dots {
    bottom: 25px;

    li button:before {
      font-size: 12px;
      color: #7d7d7d;
      opacity: 1;
    }

    li.slick-active button:before {
      color: #ff6633;
      opacity: 1;
    }
  }
}

/**
========== Section header
 */
.section-header {
  text-align: center;
  padding-bottom: 25px;

  img {
    display: block;
    width: 50px;
    margin: 0 auto 10px;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 2px;
  }

  h4 {
    font-weight: 300;
    font-size: 15px;
  }
}

/**
======= G Card
 */

.g-card {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }

  .g-card-status {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    font-size: 14px;
    padding: 2px 22px;
    font-weight: 500;
    transform: rotate(-45deg) translate(-28px, -8px);
    width: 100px;
    text-align: center;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    cursor: default;
  }

  .g-date {
    color: #ff6633;
    margin-bottom: 15px;
  }

  .g-time-down {
    display: flex;
    margin-top: 10px;
    i {
      font-size: 15px;
      color: #ff6633;
      margin-right: 5px;
    }
    & > div {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
      span {
        font-size: 13px;
        font-weight: normal;
        opacity: 0.8;
      }
    }
  }

  .g-price-container {
    min-height: 75px;
    align-items: flex-end;
  }

  .g-price {
    label {
      color: #666666;
      margin-bottom: 0;
    }

    .event-item-old-price {
      font-size: 14px;
      color: #57a8dc;
      text-decoration: line-through
    }

    p {
      font-weight: 700;
      color: #ff6633;
      font-size: 27px;
      line-height: 25px;

      span {
        font-size: 17px;
        margin-left: 2px;
      }
    }
  }

  .img-container {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .g-category {
    background: #071a35;
    border-radius: 5px;
    font-weight: 500;
    color: #ffffff;
    font-size: 15px;
    padding: 5px 30px;
    margin-bottom: 15px;
  }

  .g-title {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 4px;

    min-height: 44px;
    max-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: 0;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .g-address {
    color: #666666;
    font-size: 12px;
    max-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    margin-top: 8px;

    &:before {
      content: '';
      width: 20px;
      display: inline-block;
      height: 20px;
      background: url('../images/icon-pin.png') no-repeat left center;
      background-size: contain;
      margin-bottom: -3px;
    }
  }

  .g-card-desc {
    border-width: 0 1px 1px 1px;
    border-color: #d9d9d9;
    border-style: solid;
    border-radius: 0 0 15px 15px;
    padding: 15px;
    background-color: #fff;
  }

  .g-btn-orange {
    background-color: #ff6633;
    border-radius: 7px;
    color: #ffffff;
    align-self: flex-end;
    line-height: 30px;
    padding: 0 20px;
    margin-bottom: 5px;
    text-decoration: none;
  }
  .g-btn-grey {
    background-color: #8f8f8f;
    border-radius: 7px;
    color: #ffffff;
    align-self: flex-end;
    line-height: 30px;
    padding: 0 20px;
    margin-bottom: 5px;
    text-decoration: none;
    @media screen and (max-width: 1400px) {
      padding: 0 6px;
    }
  }
}

/**
========= Store product HP
 */
.store-pr {
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: rgba(34, 34, 43, 0.08) 0px -1px 4px -2px,
      rgba(34, 34, 43, 0.1) 0px 14px 16px -6px,
      rgba(34, 34, 43, 0.1) 0px 4px 22px 0px;
    .details {
      transform: translateY(-49px);
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }

  .details {
    position: absolute;
    transition: all 0.3s ease 0s;
    bottom: -100%;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: black;
    padding: 6px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.true {
      transform: translateY(0);
      opacity: 1;
      bottom: 0;
      .top a.sub {
        max-height: none;
        overflow: visible;
        max-width: none;
      }
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        font-size: 13px;
        color: inherit;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        display: block;
        &.sub {
          opacity: 0.6;
          max-height: 19px;
          overflow: hidden;
          max-width: 90%;
        }
        &:hover {
          color: #fe6b00;
        }
      }
      .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 30px;
        &.true {
          transform: rotate(45deg);
        }
      }
    }
    .desc {
      font-size: 12px;
      margin-top: 3px;
      display: block;
      color: inherit;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      max-height: 91px;
      &:hover {
        color: #fe6b00;
      }
    }
    .btn {
      transition: all 0.3s ease 0s;
      text-decoration: none;
      color: inherit;
      border: 0;
      background: transparent;
      padding: 0;
      width: 34px;
      height: 34px;
      cursor: pointer;
      outline: none;
      box-shadow: none;
      text-align: right;
      font-size: 21px;
      &:hover {
        color: #fe6b00;
      }
    }
  }
}

/**
========= Categories
 */
.categories {
  .categorie {
    padding: 50px 0 65px;
    .section-header {
      padding-top: 0;
    }
  }
}

/**
========= Product
 */
.product-article {
  border: 1px solid rgb(225, 225, 228);
  background-color: white;
  transition: box-shadow 0.3s ease-in-out 0s;
  padding: 0.5rem;

  img {
    width: 100%;
  }

  &.small {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    header {
      .new-price {
        font-size: 1rem;
        line-height: 14px;
        margin-bottom: 0;
      }
      .old-price {
        font-size: 0.8rem;
        line-height: 19px;
      }
    }
  }

  &:hover {
    box-shadow: rgba(34, 34, 43, 0.16) 0px -1px 2px -2px,
      rgba(34, 34, 43, 0.08) 0px 10px 14px -6px,
      rgba(34, 34, 43, 0.1) 0px 4px 18px -2px;
  }

  a {
    text-decoration: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    &>div {
      width: 65%;
    }
    a {
      color: rgb(70, 70, 80);
      font-weight: 500;
      font-size: 1rem;
      line-height: 23px;
      text-decoration: none;
    }
    .new-price,
    .old-price {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      span {
        margin-right: 5px;
      }
    }
    .new-price {
      margin: 0px 0px 0.25rem;
      color: #fd6a00;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 22px;
      span {
        font-size: 0.875rem;
        line-height: 16px;
      }
    }
    .old-price {
      color: #464650;
      font-weight: 400;
      font-size: 1rem;
      line-height: 23px;
      text-decoration: line-through;
      span {
        font-size: 0.625rem;
        line-height: 18px;
      }
    }
    .per-price {
      padding: 2px 0.25rem;
      border-radius: 2px;
      font-weight: 600;
      font-size: 0.75rem;
      color: rgb(255, 255, 255);
      background-color: rgb(63, 174, 62);
    }
  }
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    button {
      border-radius: 2px;
      border: 0;
      outline: 0px;
      padding: 0.25rem 2rem;
      height: 2rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
      background-color: #fd6a00;
      color: rgb(255, 255, 255);
    }
    a {
      color: rgb(70, 70, 80);
      font-weight: 500;
      font-size: 0.875rem;
      text-decoration: none;
      &:hover {
        color: #fd6a00;
      }
    }
  }
}

.country-dropdown {
  label {
    text-transform: uppercase;
    font-weight: 500;
  }
  button {
    cursor: pointer;
    border: 0px;
    background-color: #8c8c8c;
    color: #ffffff;
    padding: 0px 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    height: 45px;
    outline: 0 !important;
  }
  img {
    width: 30px;
    height: 20px;
  }
  .country-grid {
    margin-top: -5px;
    bottom: 22px;
    z-index: 2;
    // width: 100%;
    min-height: 45px;
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #8c8c8c;
    button {
      width: auto;
      min-width: auto;
      padding: 0px 0px;
    }
  }
}

/**
========= Catalog
 */
.catalog {
  .accordion {
    padding: 15px 0;
    border-bottom: 1px solid;
    border-color: rgb(225, 225, 228);
    cursor: pointer;
    &:hover {
      color: #fd6a00;
    }
    .title {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 21px;
        opacity: 0.6;
      }
      &:hover {
        color: #fd6a00;
        cursor: pointer;
        font-size: 16px;
      }
    }
    .list {
      list-style-type: none;
      padding: 0;
      margin-top: 14px;
      margin-bottom: 0;
      li {
        margin-bottom: 0.4rem;
        a {
          font-weight: 400;
          font-size: 15px;
          color: #888889;
          text-decoration: none;
          &:hover {
            color: #fd6a00;
          }
        }
      }
    }
  }
  .p-info {
    margin-top: 1rem;
    color: #74747b;
    font-weight: 500;
    font-size: 1rem;
    line-height: 23px;
  }
  header {
    &>img {
      width: 100%;
    }
    .brand {
      width: 200px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      text-decoration: none;
      overflow: hidden;
      display: block;
      box-shadow: 0 7px 10px #e7e7e7;
      margin: 0 12px;
      margin-top: -36px;
      img {
        max-width: 100%;
      }
    }
  }
}

/**
========= Single Product
 */
.single-product {
  padding: 60px 0;
  .title {
    margin-bottom: 1.5rem;
    color: rgb(34, 34, 43);
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 26px;
  }
  .price-block {
    display: flex;
    align-items: flex-start;
    .new-price,
    .old-price {
      display: flex;
      align-items: flex-start;
      span {
        margin-right: 8px;
      }
    }
    .new-price {
      margin: 0px 0px 0.25rem;
      color: #fd6a00;
      font-size: 2rem;
      font-weight: 700;
      line-height: 30px;
      span {
        font-size: 1.125rem;
        line-height: 16px;
        font-weight: 500;
      }
    }
    .old-price {
      color: #464650;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 23px;
      text-decoration: line-through;
      span {
        font-size: 0.625rem;
        line-height: 18px;
      }
    }
    .per-price {
      padding: 2px 0.25rem;
      border-radius: 2px;
      font-weight: 600;
      font-size: 0.75rem;
      color: white;
      background-color: #3fae3e;
      margin-left: 16px;
      margin-top: 6px;
    }
  }
  p {
    color: rgb(70, 70, 80);
    font-weight: 400;
    font-size: 1rem;
    line-height: 23px;
  }
}

.description {
  margin: 35px 0;
  h3 {
    color: #22222b;
    font-weight: 600;
    font-size: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    span {
      min-width: fit-content;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e6e6e6;
      margin-left: 12px;
    }
  }
  h5 {
    margin-top: 45px;
    opacity: 0.9;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
  }
  h6 {
    font-weight: 500;
    opacity: 0.8;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  p {
    font-size: 16px;
    opacity: 1;
    text-align: justify;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      font-size: 15px;
      margin-bottom: 2px;
      opacity: 0.8;
      &:before {
        content: '- ';
      }
    }
  }
}

/**
========== info HP
 */
.g-info {
  text-align: center;

  img {
    display: block;
    margin: 0 auto 15px;
    height: 60px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;

    strong {
      font-weight: 500;
    }
  }

  p {
    color: #7e7f81;
    line-height: 16px;
    font-size: 13px;
  }
}

.features {
  padding: 55px 0 45px;
}

/**
========= SelectBox
 */
.select-box--box {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  min-width: 85px;
}

.select-box--container {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.select-box--selected-item {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 8px 15px;
  vertical-align: middle;
}

.offer-old-price {
  margin-right: 12px;
  font-size: 14px;
  color: #57a8dc;
  text-decoration: line-through
}

.select-box--items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #cdcdcd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  z-index: 3;
  max-height: 370px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all .2s ease-in-out;
  &.shown {
    visibility: visible;
    opacity: 1;
  }
  div {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 8px;
    padding-left: 15px;
    font-size: 14px;
    transition: all .2s ease-in-out;
    &:hover {
      background-color: #fafafa;
    }
    &.disabled {
      cursor: default;
      &:hover {
        background-color: transparent!important;
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.select-box--arrow {
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  span {
    font-size: 24px;
    color: #cdcdcd;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

/**
========= Footer
 */
.main-footer {
  background-color: #f3f3f3;
}

.top-footer {
  padding: 50px 0;
}

.footer-title {
  text-transform: uppercase;
  color: #161616;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
}

.footer-left {
  p {
    color: #111111;
    margin-bottom: 35px;
    font-weight: 500;
  }
}

.footer-logo {
  margin-bottom: 30px;

  img {
    width: 170px;
  }
}

.footer-menu-bloc {
  margin-bottom: 15px;
}

.footer-menu {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: $grey-color;
  }
}

.btn-app {
  display: block;
  font-weight: 500;
  color: #ffffff !important;
  text-decoration: none !important;
  border-radius: 6px;
  padding: 12px 10px 12px 44px;
  margin-bottom: 15px;

  &.orange {
    background: #ff6633 url('../images/download-icon-1.png') left 9px center
      no-repeat;
    background-size: 26px;
  }

  &.purple {
    background: #cc3366 url('../images/download-icon-2.png') left 9px center
      no-repeat;
    background-size: 28px;
  }
}

.newsletter {
  border: 1px solid #aaaaaa;
  padding: 10px;
  border-radius: 15px;

  .text-danger {
    position: absolute;
    bottom: -42px;
  }

  p {
    font-weight: 300;
  }

  h3 {
    font-weight: 300;
  }

  .email {
    flex: 4;
    input[type='email'] {
      border: none;
      line-height: 40px;
      padding: 0 10px;
      font-weight: 500;
      width: 100%;
    }
  }

  input[type='submit'] {
    flex: 0 1;
    line-height: 40px;
    background-color: #ed6337;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.bottom-footer {
  background: #ffffff url('../images/banner/bottom-line.png') bottom no-repeat;
  background-size: 100% 5px;
  padding: 15px 0;

  p {
    margin-bottom: 0;
    color: #0a3454;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column-reverse;

      & > * {
        margin-bottom: 15px;
      }
    }
  }
}

.sm-links {
  &.light a {
    color: white!important;
  }
  a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 100%;
    text-align: center;
    line-height: 29px;
    margin-left: 6px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &.facebook {
      color: #3b5999;
      &:hover {
        background-color: #3b5999;
        border-color: #3b5999;
      }
    }
    &.google {
      color: #de4f3e;
      &:hover {
        background-color: #de4f3e;
        border-color: #de4f3e;
      }
    }
    &.instagram {
      color: #add7a5;
      &:hover {
        background-color: #add7a5;
        border-color: #add7a5;
      }
    }
    &.pinterest {
      color: #c8232c;
      &:hover {
        background-color: #c8232c;
        border-color: #c8232c;
      }
    }
    &.linkedin {
      color: #0976b4;
      &:hover {
        background-color: #0976b4;
        border-color: #0976b4;
      }
    }
    &.twitter {
      color: #02aded;
      &:hover {
        background-color: #02aded;
        border-color: #02aded;
      }
    }
    &:hover {
      color: #ffffff;
    }
  }
  img {
    width: 30px;
    margin-left: 5px;
  }
}

.payment-methods img {
  width: 250px;
  max-width: 100%;
}

/**
=========== Simple slider
 */

.simple-slider {
  padding-bottom: 60px;

  .slide-item {
    padding: 10px;
    img {
      width: 100%;
      border-radius: 8px;

      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .slick-dots {
    bottom: 20px;

    li {
      width: 45px;
      height: 6px;
      margin: 0 3px;
    }

    li button:before {
      font-size: 0;
      background-color: #e8e8e8;
      opacity: 1;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }

    li.slick-active button:before {
      background-color: #ff6633;
      opacity: 1;
    }
  }
}

/**
========== Coming soon
 */

.coming-soon {
  background-color: #200d5d;
  padding: 50px 0 70px;

  .section-header {
    color: #ffffff;
  }

  .store-pr {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    a {
      pointer-events: none;
    }
    .details {
      background-color: rgb(18, 18, 23);
      color: white;
    }
    .bottom {
      a {
        display: none;
      }
    }
  }
}

.sc-slider {
  padding-bottom: 80px;

  .slick-active .slide-item {
    opacity: 1;
  }

  .slide-item {
    padding: 10px;
    opacity: 0.5;
    transition: 0.2s ease;

    .img-container img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .slick-dots {
    bottom: 25px;

    li {
      width: 45px;
      height: 6px;
      margin: 0 3px;
    }

    li button:before {
      font-size: 0;
      background-color: #928f9a;
      opacity: 1;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }

    li.slick-active button:before {
      background-color: #ff6633;
      opacity: 1;
    }
  }
}

.mv-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  .mv-card-desc {
    padding: 10px;
  }

  .mv-title {
    font-size: 18px;
  }

  .mv-duration {
    display: block;
    color: #ff6633;

    &:before {
      content: '';
      background: url('../images/time-icon.png') no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: sub;
    }
  }

  .mv-tags {
    color: #666666;
    font-weight: 500;
  }

  .mv-rating {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #e8e8e8;
    color: #5c5c5c;
    font-weight: 700;
    display: block;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
  }
}

/**
========= Modal
 */
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: all 0.2s ease-in-out;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  &.true {
    visibility: visible;
    opacity: 1;
  }
  .back {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  section {
    background-color: white;
    padding: 25px 25px;
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;
  }
  .my-container {
    width: 1024px;
    position: relative;
    &.small-container {
      width: 765px;
    }
    @media screen and (max-width: 970px) {
      width: 100%;
      padding: 0 15px;
    }
  }
}

/**
========= Address Modal
 */
.address-modal {
  input, select {
    width: 100%;
    border: 1px solid #e6e6e6;
    background: white;
    padding: 8px 15px;
    border-radius: 4px;
    height: 39px;
  }
  .bnt-submit {
    width: auto!important;
    margin: auto;
    padding: 12px;
    padding-left: 50px!important;
    padding-right: 50px!important;
    border: 0;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.blue {
      background-color: #57a8dc;
    }
    &.green {
      background-color: #3abc00;
    }
  }
  .autocomplete {
    input {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 240px;
      height: 40px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 50%;
      margin-left: -120px;
      margin-top: 10px;
    }  
  }
}

.pac-container {
  z-index: 9999!important;
}

.address-box {
  button {
    width: auto!important;
    padding: 10px 18px!important;
    margin: 20px auto;
    margin-bottom: 0;
    border: 0;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #57a8dc;
  }
  .table {
    button {
      margin: auto 5px!important;
      padding: 0!important;
      background: transparent;
      color: #c7c7c7;
      font-size: 18px;
      &:hover {
        color: black;
      }
    }
    th, td {
      padding: 8px;
      vertical-align: middle;
      border-top: 0;
      border-bottom: 1px solid #dee2e6;
    }
    th {
      font-size: 15px;
    }
    td {
      font-size: 13px;
    }
  }
}

/**
========= Buy now modal
 */
.buy-now-modal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  &.true {
    visibility: visible;
    opacity: 1;
    .box {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
  .box {
    background-color: white;
    // min-width: 920px;
    width: 880px;
    padding: 45px 40px;
    position: relative;
    align-items: center;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.3s;
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;
    height: auto;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 985px) {
      min-width: 100%;
      & img {
        margin-bottom: 15px;
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      outline: none;
      padding: 10px;
    }
    h3 {
      font-size: 26px;
      margin-bottom: 6px;
      color: $dark-color;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #071a35;
      margin: 0;
      line-height: 22px;
    }
    table {
      width: 100%;
      margin: 24px 0;
      th {
        font-weight: 500;
        color: #5e5e5e;
        background-color: #f6f6f6;
        padding: 5px 0;
      }
      td {
        font-weight: bold;
        padding: 10px 0;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      button, a {
        color: white;
        width: 50%;
        text-transform: uppercase;
        border: 0;
        border-radius: 3px;
        padding: 9px 5px;
        font-size: 13px;
        font-weight: 400;
        outline: none;
        cursor: pointer;
        display: block;
        text-decoration: none;
        &:first-child {
          margin-right: 10px;
          background-color: #57a8dc;
        }
        &:last-child {
          margin-left: 10px;
          background-color: #3abc00;
        }
      }
    }
    .actions.plan {
      margin-top: 24px;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
  &.plan {
    .box {
      height: 90% !important;
      overflow: hidden;
    }
  }
  .plan-container {
    height: 94%;
    overflow: auto;
  }
}

/**
========= Account modal
 */
 .account-modal {
  @media screen and (max-width: 767px) {
    display: block;
    padding-top: 20px;
    .or {
      display: none!important;
    }
  }
  .loading-popup {
    background-color: transparent;
    position: fixed;
    .lds-spinner {
      transform: none;
      div:after {
        background: #ffffff;
      }
    }
  }
  .modal-container {
    position: relative;
    .close-btn {
      position: absolute;
      top: 5px;
      right: 20px;
      z-index: 9;
      cursor: pointer;
      background: none;
      border: 0;
      font-size: 28px;
      opacity: 0.5;
      outline: none;
      &:hover {
        opacity: 1;
      }
    }
    .or {
      position: absolute;
      left: 50%;
      top: 0;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      width: 82px;
      height: 82px;
      border-radius: 100%;
      text-align: center;
      line-height: 2.6;
      margin-top: -40px;
      transform: translateX(-50%);
      z-index: 4;
      background-color: #fd6a00;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.21);
    }
  }
}


/**
========== Passed events
 */

.passed-events {
  background-color: #071d39;
  padding: 50px 0;

  .section-header {
    color: #ffffff;
  }

  .g-card {
    position: relative;
    opacity: 0.5;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.g-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 40px;
  padding: 0;

  .previous a,
  .next a {
    font-size: 17px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    margin-top: 2px;
    cursor: pointer;
  }
  .previous a {
    background: #fff url('../images/left-arrow.png') left 9px center no-repeat;
    background-size: 10px;
    margin-right: 14px;
  }
  .next a {
    background: #fff url('../images/right-arrow.png') left 11px center no-repeat;
    background-size: 10px;
    margin-left: 14px;
  }
  .pagination-item {
    border: none;
    background: #fff;
    border-radius: 50%;
    font-weight: 500;
    color: #ff5722;
    font-size: 14px;
    width: 28px;
    height: 28px;
    line-height: 29px;
    margin: 2px 3px;
    display: block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;

    &.active {
      background: #ff5722;
      color: #fff;
    }
  }
  .break-me {
    a {
      color: white;
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
    }
  }
}

/**
========= Form
 */
.contact-from-header {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  h3 {
    color: #000033;
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  p {
    font-weight: 300;
    color: #3c3c3c;
    font-size: 15px;
    line-height: 18px;
  }
}

.form-container {
  max-width: 800px;
  margin: 0 auto 50px;

  &.small-container {
    max-width: 350px;
  }
}

.g-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 6px;
}

.g-input {
  margin-bottom: 13px;

  label {
    color: #000033;
    display: block;
    margin-bottom: 3px;

    span {
      color: #be3d33;
    }
  }

  .forgot {
    color: #3c3c3c;
    text-decoration: none;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
    &:hover {
      color: #fd6a00
    }
  }

  input,
  textarea {
    padding: 3px 15px;
    width: 100%;
    border: 2px solid #57a8dc;
    border-radius: 5px;
    line-height: 35px;

    &::placeholder {
      color: #9f9f9f;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9f9f9f;
    }

    &::-ms-input-placeholder {
      color: #9f9f9f;
    }
  }
}

.g-submit {
  color: #ffffff;
  background-color: #09b500;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  min-width: 150px;
  cursor: pointer;
  display: block;
  text-align: center;

  &.blue {
    background-color: #51a9dc;
  }
}

.g-social-media {
  list-style-type: none;
  padding: 0;
  display: flex;
  li {
    width: 33.333%;
    padding: 0 4px;
    button {
      width: 100%;
      display: block;
      text-align: center;
      background: black;
      color: white;
      height: 36px;
      line-height: 34px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      outline: none;
      border: 0;
      cursor: pointer;
      &.facebook {
        background-color: #46629c;
      }
      &.twitter {
        background-color: #00aced;
      }
      &.google {
        background-color: #ea634e;
      }
      i {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }
}

/**
======= Event page
 */

.event-title h1 {
  color: #000033;
  font-weight: 600;
  text-align: center;
  margin: 40px auto;
  margin-top: 70px;
}

.evnet-slider-nav {
  .slick-track {
    height: auto !important;
    display: flex;
    &>div {
      max-width: 164px!important;
    }
  }

  .img-container {
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 10px;
      border: 3px solid transparent;
      height: 95px;
    }
  }

  .slick-current {
    img {
      border-color: #fe6b00!important;
    }
  }

  &.product-slider-nav {
    .img-container {
      padding: 5px;
      img {
        border-radius: 0;
        border: 1px solid #ececec;
        height: auto;
      }
    }
  }
}

.evnet-slider-main {
  img {
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 991px) {
      height: auto!important;
    }
  }
}

.event-info {
  background-color: #031a37;
  color: #ffffff;
  border-radius: 10px;
  padding: 22px 15px;
  text-align: center;
  min-height: calc(100% - 10px);

  .event-logo {
    height: 80px;
    margin: auto;
    display: block;
    cursor: pointer;
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 40px;
    }
  }

  .event-price {
    margin: 15px 0;

    label {
      font-weight: 300;
    }

    h3 {
      font-size: 2.5rem;

      span {
        font-size: 1.7rem;
      }
    }
  }

  .event-date { 
    margin: 15px 0;

    label {
      font-weight: 300;
    }

    h5 {
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .select-box--container {
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    background-color: #2c4a6e;
    font-size: 15px;
    color: #ffffff;
    width: 100%;
  }
  .select-box--selected-item {
    height: 50px;
    padding: 0 15px;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 38px;
    span {
      font-weight: bold;
      min-width: 26%;
      text-align: right;
    }
  }
  .select-box--items {
    background: #2c4a6e;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(3, 26, 55, 0.45);
    div {
      border-color: #264264;
      font-size: 15px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: bold;
        min-width: 26%;
        text-align: right;
      }
      &:hover {
        background-color: #264264;
      }
    }
  }

  .i-button {
    background-color: #3eb100;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
    border: none;
    width: 100%;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    line-height: 20px;
    &:disabled {
      background-color: rgb(149, 149, 149);
      cursor: default;
    }
  }
}

.event-countdown {
  display: flex;
  justify-content: center;

  .countdown-item {
    width: 60px;

    span {
      display: block;
      margin: 0 auto;
    }

    span:first-child {
      width: 50px;
      height: 50px;
      font-size: 20px;
      font-weight: 700;
      border-radius: 50%;
      border: 1px solid #fe6b00;
      line-height: 46px;
      margin-bottom: 5px;
      position: relative;
      //&:after{
      //  content: '';
      //  position: absolute;
      //  display: block;
      //  width: 100%;
      //  height: 100%;
      //  border: 3px solid #fe6b00;
      //  border-radius: 50%;
      //  top: 0;
      //  left: 0;
      //}
    }

    span:last-child {
      text-align: center;
      font-size: 13px;
    }
  }
}

/**
======= Cart
 */
.box {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 25px 27px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.06);
  position: relative;
  .heading {
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 15px;
    margin-bottom: 20px;
    color: $dark-color;
    h4 {
      margin: 0;
      font-size: 28px;
      font-weight: 500;
      color: inherit;
    }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      margin-top: 3px;
      color: inherit;
    }
  }

  @media (max-width: 692px) {
    padding: 20px;
  }
}

// radios
.radio-container {
  display: block;
  position: relative;
  padding-left: 29px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 0;

  a {
    color: #57a8dc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #57a8dc;
    border-radius: 3px;
    &:after {
      content: '';
      position: absolute;
      width: 56%;
      height: 56%;  
      background: #57a8dc;
      border-radius: 2px;
      left: 4px;
      top: 4px;
      transform: scale(0);
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
  }
  input:checked ~ .checkmark:after {
    opacity: 1;
    transform: scale(1);
  }
}

.cart {
  padding: 100px 0;
  background-color: #f9f9f9;
  position: relative;

  &>.loading-popup {
    position: fixed;
    height: calc(100% - 147px);
    bottom: 0;
    top: auto;
  }

  @media (max-width: 570px) {
    padding: 50px 0;
  }

  // panier
  .panier {
    .item {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      padding: 25px 0;
      &>div:last-of-type {
        margin-bottom: 0!important;
      }
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
      .item-image {
        border-radius: 12px;
        height: 107px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 570px) {
          max-width: 200px;
        }
      }
      h4 {
        font-size: 23px;
        margin-bottom: 13px;
        padding-right: 50px;
      }
      label {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 500;
        display: block;
      }
      select {
        background-color: #2c4a6e;
        color: white;
        border-radius: 8px;
        border: 0;
        padding: 9px 12px;
        max-width: 75px;
        width: 75px;
        cursor: pointer;
      }
      input[type='text'] {
        border-radius: 5px;
        padding: 7px 16px;
        border: 2px solid #57a8dc;
        font-size: 14px;
        font-weight: 400;
        height: 39px;
        width: 100%;
      }
      .custom-image-upload {
        position: relative;
        overflow: hidden;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 7px;
        border: 2px dashed #eeeeee;
        cursor: pointer;
        color: black;
        .img {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-size: cover;
          background-position: center;
          margin-right: 15px;
        }
        i {
          font-size: 20px;
          margin-right: 7px;
        }
        input[type='file'] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
      textarea {
        border-radius: 5px;
        padding: 7px 16px;
        border: 2px solid #57a8dc;
        font-size: 14px;
        font-weight: 400;
        height: 80px;
        min-height: 80px;
        max-height: 200px;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }
      .i-select {
        max-width: none;
        width: 100%;
        padding: 9px 6px;
      }
      button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        &.close {
          position: absolute;
          z-index: 4;
          top: 0px;
          right: -15px;
          width: auto;
          color: black;
          i {
            font-size: 23px;
            margin: 0;
          }
        }
      }
      .total {
        p {
          margin: 0;
          font-size: 15px;
          &.totalN {
            font-size: 20px;
            font-weight: 700;
          }
        }
        @media (max-width: 570px) {
          display: flex;
          flex-wrap: nowrap;
          text-align: left !important;
          margin-bottom: 16px;
        }
      }
    }
  }

  .mode-livraison {
    select {
      width: 100%;
      color: #616161;
      border: 2px solid;
      padding: 12px 8px;
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 18px;
    }
    label {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #616161;
      display: flex;
      align-items: center;
      cursor: pointer;
      a {
        color: inherit;
        font-size: 20px;
        margin-left: 5px;
        text-decoration: none;
      }
    }
  }
  
  .hint {
    background-color: #f5f5f5;
    padding: 12px 38px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 300;
    line-height: 19px;
    max-width: 72%;
    margin-bottom: 14px;
    margin-top: -7px;
    p {
      margin: 0;
      //display: inline;
      strong {
        font-weight: bold;
      }
    }
    @media (max-width: 570px) {
      max-width: 100%;
    }
  }

  .mode-paiement {
    label {
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }
  .code-promo {
    .col-4 {
      max-width: none!important;
      padding: 0!important;
      margin: 0!important;
    }
    h4 {
      font-size: 21px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      a {
        font-size: 32px;
        color: inherit;
        text-decoration: none;
        margin-left: 10px;
      }
    }
    .g-input {
      margin-bottom: 0;
      width: 100%;
    }
    input {
      border: 2px solid #b8b8b8;
      padding: 8px 16px;
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: inherit;
    }
    button {
      background-color: #b8b8b8;
      border: 2px solid #b8b8b8;
      color: white;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      outline: none;
      padding: 0;
    }
  }

  button, .btn {
    width: 100%;
    border: 0;
    border-radius: 5px;
    color: white;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.green {
      background-color: #3abc00;
    }
    &.blue {
      background-color: #57a8dc;
    }
    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .total-box {
    p {
      color: #373738;
      font-weight: 700;
      margin: 0;
      font-size: 14px;
      border: 0;
    }
    hr {
      border-top: 2px solid #f1f1f1;
    }
  }

  // cart-finalized
  &.cart-finalized {
    h2 {
      font-size: 30px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 88px;
        height: 2px;
        display: block;
        background-color: #fd6a00;
      }
    }
    .text-body {
      span {
        font-size: 20px;
        margin-bottom: 13px;
        display: block;
      }
      p {
        font-size: 15px;
        margin-bottom: 7px;
      }
    }
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1370px;
  }
}

/**
========= Profile page
 */
.profile-page {
  padding: 100px 0;
  background-color: #f9f9f9;
  .menu {
    position: sticky;
    top: 180px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 20px;
        button {
          background-color: transparent;
          border: 0;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          font-size: 15px;
          outline: none;
          i {
            margin-right: 10px;
            font-size: 20px;
          }
          &:hover {
            color: #fd6a00;
          }
        }
      }
    }
  }
  .updateProfileForm {
    margin-top: 20px;
    input {
      border-radius: 5px;
      padding: 7px 16px;
      border: 2px solid #57a8dc;
      font-size: 14px;
      font-weight: 400;
      height: 39px;
      width: 100%;
    }
    button {
      width: auto !important;
      padding: 10px 18px !important;
      margin: 20px auto;
      margin-bottom: 0;
      border: 0;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #57a8dc;
    }
  }
  .user-info {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

table {
  thead tr {
    background: #f6f6f6;
    th {
      border: 0!important;
    }
  }
  td {
    color: #5a5a5a;
  }
}

/**
========= Mes commandes page
 */
.mes-commandes-page {
  padding: 100px 0;
  background-color: #f9f9f9;
  table {
    margin-top: 20px;
    button, a {
      text-decoration: none;
      color: white;
      border: 0;
      border-radius: 3px;
      padding: 4px 10px;
      font-size: 13px;
      font-weight: 500;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      margin-left: 7px;
      display: inline-block;
      &.green {
        background-color: #3abc00;
      }
      &.blue {
        background-color: #57a8dc;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-top: 18px;
    li {
      margin-bottom: 6px;
      font-size: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/**
========= Magazine page
 */
.magazine-page {
  background-color: #f9f9f9;
  .container {
    max-width: 1220px;
  }
  .article-item {
    text-decoration: none;
    color: black;
    margin-bottom: 35px;
    display: block;
    transition: all 350ms ease-in-out;
    position: relative;
    box-shadow: rgba(29, 33, 41, 0.08) 0px 2px 10px 0px;
    @media screen and (max-width: 575px) {
      box-shadow: none!important;
      padding-right: 170px;
      .article-img {
        position: absolute!important;
        top: 0;
        right: 0;
        width: 170px;
        height: 91px;
        overflow: hidden;
        .article-topic {
          font-size: 12px;
        }
      }
      .article-info {
        padding: 0 25px 20px 0!important;
      }
    }
    &:hover {
      box-shadow: rgba(29, 33, 41, 0.15) 0px 2px 40px 0px;
    }
    .article-img {
      position: relative;
      .article-topic {
        position: absolute;
        background-color: #c1463d;
        padding: 3px 8px;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .article-info {
      padding: 20px 25px;
      .article-title {
        font-size: 20px;
        min-height: 50px;
        max-height: 50px;
        overflow: hidden;
        position: relative;
        display: block;
      }
      .article-date {
        opacity: 0.7;
      }
    }
  }
  .featured-article {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-right: 0;
    .article-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 60px;
      opacity: 0.8;
    }
    .article-img {
      position: relative!important;
      width: 100%!important;
      clip-path: polygon(0% 0%, 0% 100%, 95% 100%, 100% 0%);
      @media screen and (max-width: 768px) {
        clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 95%);
      }
    }
    .topic {
      margin-top: 30px;
      @media screen and (max-width: 575px) {
        margin-top: 10px;
      }
      .article-topic {
        padding: 3px 5px;
        top: 10px;
        right: 10px;
        color: black;
        border: 1px solid black;
        font-size: 14px;
      }
    }
    .article-title {
      font-size: 30px;
      line-height: 34px;
      margin-top: 20px;
      font-weight: 700;
    }
    .article-date {
      font-size: 14px;
      opacity: 0.8;
      display: flex;
      align-items: center;
      i {
        font-size: 16px;
        margin-right: 7px;
      }
    }
    .read-more {
      font-size: 20px;
      font-weight: 600;
      color: black;
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-top: 70px;
      @media screen and (max-width: 575px) {
        display: none;
      }
      i {
        font-weight: 500;
        font-size: 28px;
        margin-left: 4px;
      }
    }
  }

  &.article-detail {
    background-color: white;
    .article-img {
      clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 85%);
      img {
        width: 100%;
      }
    }
    .article-info {
      padding-bottom: 100px;
      .container {
        max-width: 800px;
      }
      .breadcrumb {
        background-color: transparent;
        padding: 0;
        .breadcrumb-item {
          font-size: 15px;
          font-weight: 500;
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
      h1 {
        font-size: 48px;
        font-weight: 700;
      }
      span {
        font-size: 14px;
        opacity: 0.8;
        display: flex;
        align-items: center;
        margin-top: 20px;
        i {
          font-size: 16px;
          margin-right: 7px;
        }
      }
      .article-content {
        margin-top: 30px;
        p {
          font-size: 18px;
          line-height: 1.5;
          color: #545454;
          font-weight: 300;
          b, strong {
            font-weight: 600;
          }
          a {
            color: black;
            font-weight: 400;
          }
        }
      }
    }
  }
}

/**
========= Loading page
 */
.loading-page {
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  // z-index: 999999999999;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dddddd;

  padding: 150px 0;
  @keyframes rotateIcon {
    100% {
      transform: rotate(360deg);
    }
  }
  img {
    animation: rotateIcon 2.2s linear infinite;
    width: 85px;
  }
}
/**
========= loading popup
 */
.loading-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.65);
    margin-top: -10px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #57a8dc;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }  
}

/**
========= Download App Menu
 */
 .download-app-menu {
  border-bottom: 1px solid #dddddd;
  padding: 6px 0;
  .right {
    img {
      width: 28px;
      margin-right: 8px;
    }
    .title {
      display: block;
      font-size: 13px;
      font-weight: 600;
    }
    .subtitle {
      font-size: 12px;
      opacity: 0.6;
      display: block;
      margin-top: -1px;
    }
  }
  a {
    background: #57a8dc;
    color: white;
    border: 0;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 14px;
    text-decoration: none;
    display: block;
  }
  .close-btn {
    background: none;
    border: 0;
    padding: 0;
    font-size: 20px;
    margin-left: 9px;
    color: #b6b6b6;
    outline: none;
  }
}

/**
========= Cookie Reminder
 */
.cookie-reminder {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  color: white;
  padding: 15px 25px;
  button {
    background: transparent;
    color: white;
    border: 0;
    font-size: 47px;
    padding: 0;
    line-height: 1;
    outline: none;
    cursor: pointer;
  }
}

/**
========= Not Found Page
 */
.not-found-page {
  .p404 {
    font-size: 150px;
    color: #ed6337;
    line-height: 120px;
    margin-bottom: 22px;
  }
  h1 {
    font-weight: 400;
    color: #373737;
  }
  p {
    font-size: 15px;
    font-weight: 500;
    color: #8f8f8f;
  }
  .btn {
    margin-top: 25px;
    border-radius: 5px;
  }
  padding: 100px 0;
}

/**
======= Search Page
 */
.search-page {
  padding: 90px 0 80px;
  h1 {
    font-weight: 400;
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
    margin-top: 16px;
  }
}

/////////// oauth-loading
.oauth-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999999999999999999999999999999;
}

.forgot-password-modal .loading-popup {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  .lds-spinner {
    div:after {
      background: #57a8dc;
    }
  }
}

.map-location {
  position: absolute;
  top: 10px;
  left: 10px;
  button {
    border-radius: 3px;
    background-color: white;
    color: black;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
    padding-left: 10px!important;
    padding-right: 10px!important;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
  }
}

@media (max-width: 991px) {
  .small-events-slider-hidden-on-mobile {
    display: none;
  }
}

.Toastify__toast-container {
  z-index: 9999999999999999;
}

.provider {
  .section-header {
    margin-top: 30px;
    img {
      display: block;
      width: auto;
      margin: 0 auto 10px;
      max-width: 300px;
    }
  }
}


.header-countdown {
  border-radius: 0;
  gap: 32px;
  padding-top: 12px;
  padding-bottom: 12px; 
  .countdown-item  {
    width: 55px;
    font-weight: bold;
    span:first-child {
      line-height: 38px;
      width: 40px;
      height: 40px;
      font-size: 18px;
      border-color: #ff0000;
      border-width: 1px;
    }
  }
  background-color: #fca732;
  &:hover {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
}


.header-countdown h4 {
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.header-countdown p {
  padding: 8px;
  background-color: #ff0000;
  font-weight: bold;
}

.ezy-description {
  font-size: 16px;
  font-weight: 500;
  a {
    color: #ed6337;
    &:hover {
      color: #ed6337;
      text-decoration: none;
   }
  }
}